import { Link } from 'gatsby'
import type { Item } from 'src/sdk/checkout/data/types'

import { ProductFlag } from './components/ProductFlag'
import { ProductQuantity } from './components/ProductQuantity'
import { ItemInformation } from './components/ItemInformation'

interface ProductItemProps {
  item: Item
}

export function ProductItem({ item }: ProductItemProps) {
  const {
    listPrice,
    productName,
    name,
    imageUrl,
    detailUrl,
    availability,
    additionalInformation,
    sellingPrice,
    hasDiscount,
  } = item

  const isProductAvailable = availability === 'available'

  return (
    <div className="grid grid-cols-2 grid-rows-[1fr_auto_auto] gap-x-6 sm:gap-x-8 w-full sm:py-4 sm:grid-cols-[1fr_3fr_1fr] sm:grid-rows-2">
      <Link className="row-span-2 sm:-my-4 w-[156px] h-[156px]" to={detailUrl}>
        <ProductFlag item={item} />

        <div
          className={`${
            !isProductAvailable ? 'opacity-30' : ''
          } flex justify-center items-center bg-restructure-background-secondary mix-blend-multiply overflow-hidden w-[156px] h-[156px] `}
        >
          <img
            className="mix-blend-darken bg-restructure-background-secondary"
            alt={productName}
            src={imageUrl.replace('80-80/', '156-156/')}
            width={156}
            height={156}
          />
        </div>
      </Link>
      <div className="flex flex-col">
        <Link
          to={detailUrl}
          className="desktop-body-regular-text2 gap-2 flex flex-col mb-2 text-restructure-primary sm:max-w-[384px] sm:text-ellipsis sm:whitespace-nowrap  sm:overflow-hidden sm:block"
        >
          {productName || name}
        </Link>

        <ItemInformation additionalInformation={additionalInformation} />
      </div>
      <div
        className={`${
          !isProductAvailable ? 'flex-col gap-2 items-start' : 'gap-4'
        } flex mt-6 col-span-2 row-start-3 sm:mt-0 sm:col-start-2 sm:row-start-2 sm:flex-row sm:items-end`}
      >
        <ProductQuantity item={item} />
      </div>
      <div className="flex flex-col pt-2 col-start-2 sm:items-end sm:col-start-3 sm:row-start-1">
        <span className="font-semibold desktop-price-card text-restructure-primary">
          {sellingPrice}
        </span>
        {hasDiscount && (
          <span className="text-font-size-3 text-restructure-tertiary line-through">
            {listPrice}
          </span>
        )}
      </div>
    </div>
  )
}
